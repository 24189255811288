<template>
  <Row class="px-1">
    <i-col span="24" class="align-justify">
      <Row :gutter="32">
        <i-col span="24" class="align-justify">
          <Form :model="formItem" :label-width="80">
            <FormItem label="单位名称">
              <i-col span="8" class="align-justify">
                <Input v-model="formItem.input" placeholder="请输入机构名称"></Input>
              </i-col>
            </FormItem>
            <FormItem label="单位logo">
              <i-col span="8" class="align-justify">
                <Upload multiple type="drag" action="//jsonplaceholder.typicode.com/posts/">
                  <div style="padding: 20px 0">
                    <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                    <p>请点击或将图片拖放到此处来上传您的单位品牌logo</p>
                  </div>
                </Upload>
              </i-col>
            </FormItem>
            <FormItem label="服务时间">
              <i-col span="8" class="align-justify">
                <Input v-model="formItem.input" placeholder="请输入服务时间"></Input>
              </i-col>
            </FormItem>
            <FormItem label="联系地址">
              <i-col span="8" class="align-justify">
                <Input
                  v-model="formItem.input"
                  type="textarea"
                  :autosize="{minRows: 2,maxRows: 5}"
                  placeholder="请输入联系地址，多个地址请换行隔开"
                ></Input>
              </i-col>
            </FormItem>
            <FormItem label="联系电话">
              <i-col span="8" class="align-justify">
                <Input
                  v-model="formItem.input"
                  type="textarea"
                  :autosize="{minRows: 2,maxRows: 5}"
                  placeholder="请输入联系电话，多个地址请换行隔开"
                ></Input>
              </i-col>
            </FormItem>
            <FormItem label="单位简介">
              <i-col span="10" class="align-justify">
                <editor-bar v-model="formItem.textarea" :isClear="isClear" @change="change"></editor-bar>
              </i-col>
            </FormItem>
            <FormItem label="">
              <Button type="primary">提交</Button>
            </FormItem>
          </Form>
        </i-col>
      </Row>
    </i-col>
  </Row>
</template>
<script>
import EditorBar from "../components/wangeditor";
export default {
  name: "systemsetting",
  components: {
    EditorBar,
  },
  data() {
    return {
      isClear: false,
      formItem: {
        input: "",
        select: "",
        radio: "male",
        checkbox: [],
        switch: true,
        date: "",
        time: "",
        slider: [20, 50],
        textarea: "",
      },
    };
  },
  mounted() {},
  methods: {
    change(val) {},
  },
};
</script>

<style lang="less" scoped="scoped">
</style>